import React, { useState } from 'react'
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import { useLocation } from 'react-router-dom'
import { LazyLoadImage } from "react-lazy-load-image-component"

const Header = () => {
    const navigation = [
        { name: "Home", path: "#home" },
        { name: "About us", path: "#about" },
        { name: "features", path: "#features" },
        { name: "gallery", path: "#gallery" },
        { name: "value", path: "#value" },
        { name: "3d dashboard", path: "#dashboard" },
        { name: "application", path: "#application" },
        { name: "library", path: "#library" },
        { name: "contact", path: "#contacts" },
    ]
    const location = useLocation()


    const [mobileNav, setMobileNav] = useState(false)
    return (
        <div className='h-16 shadow-xl z-10 fixed w-screen bg-opacity-95 bg-[#152535]'>
            <div className='flex h-full px-2 md:px-5 justify-between items-center'>
                <LazyLoadImage className='md:h-16 h-16 p-1.5 ' src='./Assets/Logo/Basemap.png' />
                <ul className='uppercase hidden nav lg:flex font-semibold lg:space-x-4 xl:space-x-6 lg:pr-5'>{navigation.map((items) =>
                    <li key={items.name} className={`text-white  nav-menu  whitespace-nowrap  tracking-wider `}><a className='cursor-pointer pb-1' href={items.path}>{items.name}</a></li>)}</ul>
                <MenuIcon onClick={() => { setMobileNav(true) }} className='h-8 lg:hidden text-white pr-3' />
            </div>
            <div className={`${mobileNav ? "block" : "hidden"} h-screen lg:hidden w-screen absolute top-0 bg-black p-5   bg-opacity-25`}>
                <div className='h-full bg-gray-100 relative rounded-md'>
                    <ul className='uppercase flex nav flex-col  space-y-6 pl-6 pt-6 font-bold'>{navigation.map((items) => 
                        <li key={items.name} className={` text-sky-900 whitespace-nowrap nav-menu transition-all delay-75  tracking-wider hover:scale-110 cursor-pointer`} onClick={() => { setMobileNav(false) }}><a href={items.path}>{items.name}</a></li>)}</ul>
                    <XIcon onClick={() => { setMobileNav(false) }} className='h-7 absolute right-2 top-2 text-sky-800' />
                </div>
            </div>
        </div>
    )
}

export default Header