import React from 'react'


export const Value = () => {
    return (
        <div className='lg:pt-20 pt-5 page-section flex-col items-center w-full flex justify-center ' id="value">
            <div className=' font-bold lg:text-4xl text-2xl  uppercase text-[#152535] bg-opacity-80 '>Value of 3d gis</div>
            <img alt="Image of" className='pointer-events-none px-3' data-aos="zoom-in" data-aos-delay="100" src="./Assets/Value/Asset 42.png" />
            <img alt="Image of" id="dashboard" className='pointer-events-none page-section px-3 lg:pt-20 pb-5' src="./Assets/Value/create.png" />
        </div>
    )
}
