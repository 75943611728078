import React from 'react'
import {LazyLoadImage} from "react-lazy-load-image-component"

const Application = () => {
    return (
        <div className='bg-[#152535] page-section lg:pt-20 pt-5 flex flex-col w-full' id="application">
            <span className='self-start w-full font-bold lg:text-4xl text-2xl  uppercase text-[#ea5765] px-4'>Application of 3d gis</span>
            <LazyLoadImage className='lg:m-40  lg:mt-0' data-aos="zoom-in" data-aos-delay="100" src="/Assets/Application/Asset 49.png" />
        </div>
    )
}
export default Application