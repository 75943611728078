import React from 'react'
import {LazyLoadImage} from "react-lazy-load-image-component"

const Footer = () => {
    return (
        <div className='relative w-full flex md:justify-end justify-center  items-center p-8 bg-[#152535] h-auto'>
            <img alt="Image of" className=' left-0 hidden md:inline-block md:absolute h-full bottom-0' src="./Assets/Library/Asset 52.png" />
            <div className='flex items-center flex-col md:flex-row md:space-x-10'>
                <img alt="Image of" className='h-32 mb-4 md:mb-0' src="./basemap310.png" />
                <div className='flex flex-col font-light text-gray-200'>
                    <span className='text-white font-semibold'>Head Office:</span>
                    <span className='text-[#8fb686] text-opacity-75'>3D Basemap Inc.</span>
                    <span >52 Skytop St, Suit #331</span>
                    <span>San Jose, CA 95134</span>
                    <div className='flex flex-col mt-6'>
                        <span className='text-white font-semibold'>For queries:</span>
                        <span>info@3dbasemap.com</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer