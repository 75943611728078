import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import About from "./Components/About";
import Features from "./Components/Features";
import Gallery from "./Components/Gallery";
import Header from "./Components/HeaderComponents/Header";
import Home from "./Components/HomeComponent/Home";
import { Value } from "./Components/Value";
import $ from "jquery"
import Application from "./Components/Application";
import Library from "./Components/Library";
import Footer from "./Components/Footer/Footer";
import { ArrowCircleUpIcon } from "@heroicons/react/outline";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid";


function App() {
  useEffect(() => {
    // window.scroll(0,0)
    AOS.init();
    AOS.refresh();
  }, []);
  $(function () {
    $(window).on('scroll', function () {
      var WindowTop = $(window).scrollTop();
      $('.page-section').each(function (i) {
        if (WindowTop > $(this).offset().top - 80 &&
          WindowTop < $(this).offset().top + $(this).outerHeight(true)
        ) {
          $('.nav > li > a').removeClass('active');
          $('.nav li').eq(i).find('a').addClass('active');
        }
      });
    });
  });
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $('.back-to-top').fadeIn('slow');
    } else {
      $('.back-to-top').fadeOut('slow');
    }
  });
  const [modal, setModal] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  return (
    <Router>
      <div className="h-screen relative  select-none" >
        <a href="#"><ArrowCircleUpIcon className="lg:h-14 h-10 fixed back-to-top bottom-4 right-4 bg-blue-700 z-40 rounded-full text-white" /></a>
        <Header />
        <Home />
        <div className={`${modal ? "absolute" : "hidden"} flex top-20 z-30 w-full justify-center p-2`}  >
          <div className="h-auto space-y-6 flex flex-col m-2 p-2 md:w-96 fixed items-center rounded-md bg-white">
            <span className="text-3xl font-semibold mt-5">Thankyou</span>
            <p className="text-center font-semibold">Your submission is received and we will contact you soon.</p>
            <CheckCircleIcon className="md:h-20 h-10 text-green-600 " />
          </div>
        </div>
        <div className={` ${errorModal ? "absolute" : "hidden"} flex top-20 z-30 w-full justify-center p-2`} >
          <div className="h-auto space-y-6 flex flex-col m-2 p-2 fixed md:w-96 items-center rounded-md bg-white">
            <span className="text-3xl font-semibold mt-5">Error</span>
            <p className="text-center font-semibold">Something Went Wrong.</p>
            <ExclamationCircleIcon className="md:h-20 h-10 text-red-600 " />
          </div>
        </div>
        <About />
        <Features />
        <Gallery />
        <Value />
        <Application />
        <Library modal={setModal} error={setErrorModal} />
        <Footer />
        <div className="h-20 flex justify-center text-sm md:text-base items-center text-white bg-[#0f1821]">
          © Copyright 3D Basemap Inc. All Rights Reserved
        </div>
      </div>
    </Router>
  );
}

export default App;

