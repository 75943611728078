import React, { useEffect, useState } from 'react'
import { ArrowUpIcon } from "@heroicons/react/outline";
import { ArrowDownIcon } from "@heroicons/react/solid";
import {LazyLoadImage} from "react-lazy-load-image-component"
import $ from "jquery"

const Home = () => {
    const [mission, setMission] = useState(false)
    const [mission2, setMission2] = useState(false)
    useEffect(()=>{
        $(".moving-bg-demo").mousemove(function(e){
            var moveX=(e.pageX* -1/15);
            var moveY=(e.pageY* -1/15);
            $(this).css('background-position',moveX +'px ' + moveY + 'px');
        });
    })
    
    return (
        <div className="lg:h-h-[850px] md:h-[800px] h-[500px] moving-bg-demo w-full relative page-section" id="home">
            {/* <LazyLoadImage className="w-full moving-bg-demo h-full object-cover pointer-events-none" src="./Assets/dallas_cover.jpg" data-aos="zoom-in" data-aos-delay="100" /> */}
            <div className={`${mission || mission2 ? "bottom-64" : "bottom-20"} absolute  md:left-4  xl:top-96 left-3 md:w-4/6 lg:w-2/4 flex flex-col md:space-y-5`}>
                <span className="lg:text-6xl md:text-4xl text-2xl font-bold text-white text-shadow lg:whitespace-nowrap">INTRODUCING REVOLUTIONARY</span>
                <span className="lg:text-6xl md:text-4xl text-2xl font-bold text-white text-shadow">METAVERSE IN GIS</span>
                <span className="md:text-4xl text-xl font-semibold text-sky-400 text-shadow capitalize" >For better visualization, analysis, planning and simulation</span>
                {/* <img alt="Image of" className='w-screen' src="/Assets/PngImages/companyLogo.png" /> */}
                {/* <div className="flex items-center md:space-x-4 space-x-2">
                    <span className="md:text-4xl text-lg font-semibold text-yellow-400">EXPLORE YOUR WORLD IN 3D </span>
                    <PlayIcon className="md:h-16 h-8 bg-gray-200 rounded-full text-yellow-500 hover:text-amber-400 hover:scale-110 transition-all  duration-150 cursor-pointer"  data-aos="zoom-in"/>
                </div> */}
            </div>
            <div className={`${mission2 ? "lg:right-[400px] md:right-[325px] right-[226px]" : "lg:right-36 md:right-32 right-24"} bottom-0   flex space-x-2  absolute '`}>
                <div className=" font-semibold  flex space-x-2">
                    <div className={`${mission ? "lg:w-96 md:w-80 w-56" : "w-auto"} text-white bg-opacity-80 md:p-2 p-1.5 rounded-t-md bg-[#152535] `}>
                        <span className={`${mission ? "text-red-500" : "text-white"} flex whitespace-nowrap cursor-pointer lg:text-xl md:text-base text-xs items-center rounded-t-md`} onClick={() => { setMission(!mission); setMission2(false) }}>01 Mission {mission ? <ArrowDownIcon className="md:h-5 h-4 ml-2" /> : <ArrowUpIcon className="md:h-5 h-4 ml-2" />} </span>
                        <div className={`${mission ? "block" : "hidden"}`}>
                            <span className=' md:text-base text-xs font-thin'> At 3D Basemap our mission is to create highly detailed 3D city models using satellite imagery and make it readly available worldwide.Using location as a common denominator, we aim to document attributes in 360° and measure it against multiple parameters for the purpose of providing a holistic set of information.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bottom-0 lg:right-2  flex space-x-2 right-0  absolute '>
                <div className=" font-semibold  flex space-x-1">
                    <div className={`${mission2 ? "lg:w-96 md:w-80 w-56" : "w-auto"} text-white bg-opacity-80 md:p-2 p-1.5 rounded-t-md bg-[#152535] `}>
                        <span className={`${mission2 ? "text-red-500" : "text-white"} flex whitespace-nowrap  cursor-pointer lg:text-xl text-xs md:text-base items-center rounded-t-md`} onClick={() => { setMission2(!mission2); setMission(false) }}>02 Vision {mission2 ? <ArrowDownIcon className="md:h-5 h-4 ml-2" /> : <ArrowUpIcon className="md:h-5 h-4 ml-2" />} </span>
                        <div className={`${mission2 ? "block" : "hidden"}`}>
                            <span className=' md:text-base text-xs text-center font-thin'>We envision 3D Basemap to host a 3D Library of the entire world to offer the best solutions for governance, city planning and enterprises. We want to accelerate the process of decision making and planning through validated real time data. Our ultimate pursuit is to eradicate the ambiguity around geoinformation.</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Home
