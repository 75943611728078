
import { LocationMarkerIcon, UsersIcon } from '@heroicons/react/solid'
import React from 'react'
import {LazyLoadImage} from "react-lazy-load-image-component"

const About = () => {
    const CompanyLogo = [
        { imgPath: "./Assets/CompanyLogo/ArcGISPro.png" },
        { imgPath: "./Assets/CompanyLogo/ArcGISUrban.png" },
        { imgPath: "./Assets/CompanyLogo/ArcGISHub.png" },
        { imgPath: "./Assets/CompanyLogo/ArcGISBim.png" },
        { imgPath: "./Assets/CompanyLogo/ArcGISEarth.png" },
        { imgPath: "./Assets/CompanyLogo/ArcGISOnline.png" },
        { imgPath: "./Assets/CompanyLogo/CityEngine.png" },
        { imgPath: "./Assets/CompanyLogo/Unity.png" },
        { imgPath: "./Assets/CompanyLogo/Unreal.png" },
    ]
    return (
        <div className='w-full h-auto flex flex-col bg-gray-100 items-center page-section' >
            <img alt="Image of" className='mt-10 rounded px-20 py-1.5  hidden lg:block' src="/Assets/Logo/Logo_2.png" />
            <span className='text-center lg:text-4xl md:text-2xl text-sm mt-5 lg:hidden top-20 font-bold text-[#ea5765] '>METAVERSE FOR STATE & LOCAL AND FEDERAL GOVERNMENT</span>
            <div className='flex flex-wrap md:gap-20 lg:hidden gap-10 my-14 mt-9 px-4  w-screen justify-center'>
                {CompanyLogo.map((i) => (
                    <LazyLoadImage key={i.imgPath} className='md:w-56 pointer-events-none w-36 md:last:w-72 last:w-52  object-contain'  src={i.imgPath} />
                ))}
            </div>
            <LazyLoadImage className='lg:w-5/6 mt-5 pointer-events-none' src="./Assets/Aboutus/About1.png"  id="about" />
            <LazyLoadImage className='lg:w-3/6 mt-6 pointer-events-none' src="./Assets/Aboutus/flow.png" />
           
            <span className='lg:text-2xl font-semibold uppercase border-red-600 border-2 px-3 my-5 cursor-pointer transition-all duration-300  hover:bg-red-600 hover:text-white rounded-md text-sky-900 '><a href="#contacts">Request for smaple data</a></span>
        </div>





    )
}

export default About